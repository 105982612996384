import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest
} from "@/libs/axios";
// 查询考试专有名单库
export const getZymdk = params => {
  return getRequest("/yethan/register/kszymdk/listPage", params);
};
//创建考试专有名单库
export const creatKszymdk = params => {
  return postRequest("/yethan/register/kszymdk/creatKszymdk", params);
};


// 专有名单库修改
export const editZymdk = params => {
  return putRequest("/yethan/register/kszymdk/edit", params);
};
// 专有名单库删除
export const deleteZymdk = params => {
  return deleteRequest("/yethan/register/kszymdk/del", params);
}
